<template>
  <iframe-gamma/>
</template>

<script>
import IframeGamma from './components/IframeGamma.vue'

export default {
  name: 'App',
  components: {
    IframeGamma
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
  padding: 0;
  border: none;
}
body{
  margin: 0;
}
</style>
