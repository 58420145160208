<template>
  <iframe src="https://gamma.app/embed/n9be2twkeldp7o9" style="width: 100%; max-width: 100%; height: 100vh" allow="fullscreen" title="Polanki Street Community in Poland"></iframe>
</template>

<script>
export default {
  name: 'IframeGamma'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 0px;
}
a {
  color: #42b983;
}
</style>
